<template>
  <div class="content">
    <h2>Política de Privacidade</h2>

    <p>
      Nós, da Otto Finanças, estamos comprometidos em resguardar sua
      privacidade. O intuito deste documento é esclarecer quais informações são
      coletadas dos usuários de nossos sites
      <router-link to="/" target="_blank">www.ottofinancas.com.br</router-link>
      – e respectivos serviços – e de que forma esses dados são manipulados e
      utilizados.
    </p>

    <p>
      Alertamos que se você não concorda com o conteúdo desta política, não é
      recomendável baixar nossos materiais nem utilizar quaisquer de nossos
      serviços.
    </p>

    <h3>Sobre a coleta de dados</h3>

    <p>Em nossos sites, as informações são coletadas das seguintes formas:</p>

    <p>
      Informações fornecidas por você – Coletamos informações de identificação
      pessoal, como nome, telefone, email, empresa em que trabalha e cargo, via
      preenchimento dos formulários para download de nossos conteúdos gratuitos.
      Eventualmente, a solicitação de algumas informações podem ser feitas por
      meio de contato direto da Otto Finanças com os usuários via email.
    </p>

    <p>
      Informações de navegação no site – Quando você visita nosso site, é
      inserido um ‘cookie’ no seu navegador por meio do software Google
      Analytics, para identificar quantas vezes você retorna ao nosso endereço.
      São coletadas, anonimamente, informações como: endereço, IP, localização
      geográfica, fonte de referência, tipo de navegador, duração da visita e
      páginas visitadas.
    </p>

    <p>
      Histórico de contato – Aqui na Otto Finanças armazenamos informações a
      respeito de todos os contatos já realizados com nossos usuários, como
      conteúdos baixados a partir de nossas páginas e interações via email.
    </p>

    <h3>Sobre o uso de suas Informações Pessoais</h3>

    <p>
      O presente termo permite que a Otto Finanças use suas informações pessoais
      para diferentes finalidades.
    </p>

    <p>
      O email é utilizado para a operação de envio do material ou informação por
      você requisitada no preenchimento do formulário. Também pode ser usado
      para envio de Newsletters relacionadas ao tema Finanças.
    </p>

    <p>
      Por fim, o email será utilizado ainda para comunicar o lançamento de novos
      materiais gratuitos ou de novos produtos da Otto Finanças e parceiros. No
      entanto, o usuário pode cancelar a assinatura a qualquer momento.
    </p>

    <p>
      Os dados de download poderão ser divulgados como pesquisas e estatísticas,
      não sendo reveladas abertamente nenhuma informação pessoal, a menos que
      autorizada explicitamente.
    </p>

    <p>
      Funcionários da Otto Finanças poderão eventualmente entrar em contato via
      email ou telefone para fazer pesquisas ou apresentar produtos e serviços.
    </p>

    <p>
      Suas informações pessoais serão compartilhadas com nossos parceiros apenas
      quando a parceria estiver explícita na página onde consta o formulário
      para o recebimento de conteúdo educativo.
    </p>

    <h3>Sobre o acesso às suas informações pessoais</h3>

    <p>
      Poderão ver suas informações pessoais apenas funcionários da Otto
      Finanças. Eventualmente, caso a inserção de suas informações se dê em
      ações criadas em parcerias, os parceiros explicitamente identificados
      também terão acesso à informação. Nenhuma informação pessoal poderá ser
      divulgada publicamente.
    </p>

    <p>
      A Otto Finanças também se compromete a não vender, alugar ou repassar suas
      informações para terceiros. A única exceção está em casos em que essas
      informações forem exigidas judicialmente.
    </p>

    <p>
      Além disso, embora trabalhemos com boas práticas de proteção e segurança,
      nenhum serviço web possui 100% de garantia contra invasões e não podemos
      nos responsabilizar caso isso ocorra.
    </p>

    <p>
      Poderemos compartilhar seus dados mediante seu consentimento, por meio de
      conexão com plataforma de parceiros, para recomendação de oferta ou
      produto/serviço, bem como para sincronizarmos suas contas bancárias,
      mantidas em diversas instituições financeiras (“integração bancária”). O
      compartilhamento será feito apenas quando for necessário para a oferta ou
      contratação do produto/serviço escolhido por você.
    </p>

    <p>
      Para acessar seus dados financeiros em bancos ou instituições financeiras,
      nós utilizamos nossa própria tecnologia, ou contratamos parceiros
      específicos para fornecimento deste serviço para nós, como a Belvo
      Tecnologias LTDA (<a href="https://belvo.com">https://belvo.com</a>).
    </p>

    <p>
      Com parceiros indicados acima, nós compartilhamos seus dados bancários
      (banco, agência e conta corrente/poupança), e a senha utilizada para
      acessar o internet banking. Teremos acesso aos seus dados financeiros
      apenas no modo de visualização. Nós e nossos parceiros não conseguimos
      interferir nos seus ativos ou realizar qualquer movimentação na sua conta
      bancária. Além disso, todos os seus dados ficam criptografados em nossa
      base, e garantimos toda segurança necessária.
    </p>

    <p>
      Ao optar pela integração bancária no OTTO, você precisa concordar e
      consentir com as políticas de privacidade de nosso parceiro BELVO,
      disponibilizadas a você em nossa plataforma, no ato da adesão à integração
      bancária. Caso não deseje fornecer seu consentimento, a OTTO não irá
      compartilhar nenhum dado pessoal seu, nem realizar a integração bancária.
    </p>

    <p>
      Os dados pessoais poderão ser compartilhados para cumprir obrigação legal,
      contratual ou administrativa, aperfeiçoar os produtos ou serviços
      ofertados pela OTTO ou em caso de a OTTO contar com parceiros externos que
      prestem serviços a ela de Alojamento WEB ou manutenção.
    </p>

    <p>
      Os dados do Usuário poderão, ainda, ser repassados a terceiros de modo
      oneroso ou não, em formato de dado estatístico e anônimo, sem informações
      individualizadas, e utilizados para fins publicitários, garantindo-se que
      não será possível a identificação do Usuário por meio do anúncio.
    </p>

    <h3>Sobre o compartilhamento de conteúdo nas redes sociais</h3>

    <p>
      Ao clicar nos botões de compartilhamento de conteúdo nas mídias sociais
      disponíveis em nossas páginas, o usuário estará publicando o conteúdo por
      meio de seu perfil na rede selecionada. A Otto Finanças não têm acesso ao
      login e senha dos usuários nessas redes, nem publicará conteúdo em nome do
      usuário sem que ele realize esta ação.
    </p>

    <h3>
      Sobre o cancelamento da assinatura e alteração/exclusão de informações
      pessoais
    </h3>

    <p>
      Você pode optar por não receber mais qualquer tipo de email da Otto
      Finanças.
    </p>

    <p>
      Em todos os emails que enviamos há sempre um link para cancelar a
      assinatura disponível nas últimas linhas. Ao clicar nesse link você será
      automaticamente descadastrado da lista.
    </p>

    <p>
      É importante mencionar que ao preencher qualquer formulário novamente
      ficará caracterizada a reinserção do seu email à lista. Portanto, a
      requisição de cancelamento deve ser feita novamente caso seja de seu
      interesse.
    </p>

    <p>
      Para alterar suas informações pessoais ou mesmo excluí-las do nosso banco
      de dados, basta enviar um email para
      <a href="mailto:contato@ottofinancas.com.br" target="_blank"
        >contato@ottofinancas.com.br</a
      >.
    </p>

    <h3>Mudanças na Política de Privacidade</h3>

    <p>
      Essa Política de Privacidade pode passar por atualizações. Desta forma,
      recomendamos visitar periodicamente esta página para que você tenha
      conhecimento sobre as modificações.
    </p>

    <p>
      Antes de usar informações para outros fins que não os definidos nesta
      Política de Privacidade, solicitaremos sua autorização.
    </p>

    <h3>Contato com a Otto Finanças para esclarecimento de dúvidas</h3>

    <p>
      Qualquer dúvida em relação à nossa política de privacidade pode ser
      esclarecida entrando em contato conosco.
    </p>

    <p>
      Envie um email para
      <a href="mailto:contato@ottofinancas.com.br" target="_blank"
        >contato@ottofinancas.com.br</a
      >.
    </p>

    <p>
      Estamos localizados na Rua Domingos Barbosa de Araújo, Nº 519, Sl 202 -
      Kalilândia – Feira de Santana/BA, CEP 44001-280
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
p {
  color: #999898;
  font-weight: 500;
  font-size: 14px;
  margin: 14px 0;
}

a {
  font-size: 14px !important;
}

ol {
  list-style: decimal;
  list-style-position: inside;
  color: #737373;
}

@media (min-width: 720px) {
  ol {
    padding-left: 40px;
  }
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 50px;
  color: #737373;
}

h3 {
  font-size: 19px;
  color: #737373;
}

.inner-list {
  list-style: inside;
  font-size: 16px !important;
}
</style>
